<template>
    <div class="website">
        <div class="wrap">
            <h1 class="title wow animated fadeInDown">
                <span class="gradient">{{$t('common.contact')}}</span>
            </h1>

            <div class="text_box wow animated fadeInUp">
                <p class="item">
                    {{$t('website.contact.text1')}}
                </p>
                <p class="item">
                    {{$t('website.contact.text2',{version:vNum})}}
                </p>
            </div>

            <div class="code_box wow animated fadeInUp">
                <div class="item">
                    <!-- <div class="icon">
                        <img src="@/assets/images/official/code1.jpg" alt="市值机器人, 做市机器人, Market Management System, Crypto Market Management, Dex Bot, Swap Bot, liquidity.">
                    </div> -->
                    <div class="bottom_box">
						<p>{{$t('website.contact.business')}}</p>
						<!-- <p class="number">13155477337</p> -->
						<p class="number">Telegram: 
                            <span class="telegram" @click="jumpTelegram('https://t.me/jessie7337')">@jessie7337</span>
                        </p >
						<!-- <p class="number">WeChat: Jessie7337</p > -->
                    </div>
                </div>
                <div class="item">
                    <!-- <div class="icon">
                        <img src="@/assets/images/official/code2.jpg" alt="市值机器人, 做市机器人, Market Management System, Crypto Market Management, Dex Bot, Swap Bot, liquidity.">
                    </div> -->
                    <div class="bottom_box">
						<p>{{$t('website.contact.service')}}</p>
						<!-- <p class="number">13155477337</p> -->
						<p class="number">Telegram: 
                            <span class="telegram" @click="jumpTelegram('https://t.me/Light7665')">@Light7665</span>
                        </p >
						<!-- <p class="number">WeChat: Light7665</p > -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { WOW } from 'wowjs'
import {mapGetters} from "vuex"
export default {
    data(){
        return{

        }
    },
    mounted(){
        this.wow();
    },
    computed:{
        ...mapGetters(['version']),
        vNum(){
            var versionArr=this.version.split('.')
            return versionArr[0]+'.'+versionArr[1]
        }
    },
    methods:{
        wow(){
            // if( document.querySelectorAll('.wow').length ){
            //     if (! (/msie [6|7|8|9]/i.test(navigator.userAgent))) {
            var wow = new WOW({
                boxClass:     'wow',      // animated element css class (default is wow)
                animateClass: 'animated', // animation css class (default is animated)
                offset:       50,          // distance to the element when triggering the animation (default is 0)
                mobile:       true,       // trigger animations on mobile devices (default is true)
                live:         true       // act on asynchronously loaded content (default is true)
            });
            this.$nextTick(() => {
                wow.init();
            });
            //     };
            // };
        },
        jumpTelegram(path){
            window.location.href=path
        },
    },
}
</script>
<style scoped="scoped" lang="stylus">
	@import './homeContact.styl';
	@import '~@/assets/style/frames.styl';
</style>